import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const MemberBill = () => {
    const [username, set_username] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    const [date, set_date] = useState('');
    const [unique_id, set_unique_id] = useState('');
    const [array, set_array] = useState([]);
    const [address, set_address] = useState('');
    const [qrcode, set_qrcode] = useState('');
    const [logo, set_logo] = useState('');
    const [slot, set_slot] = useState('');
    const [package1, set_package1] = useState('');
    var total_amount = 0;


    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/memberBill';
    const items = JSON.parse(localStorage.getItem('auth'));

    const params = useParams();

    // console.log(baseURL);
    async function getBills() {
        var result = await axios.get(baseURL + '/' + params.id, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        console.log(result);
        set_array(result.data.data.bills);
        set_username(result.data.data.name);
        set_phone(result.data.data.phone);
        set_email(result.data.data.email);
        set_unique_id(result.data.data.unique_id);
        set_date(result.data.data.date);
        set_address(result.data.data.address);
        set_date(result.data.data.date);
        set_slot(result.data.data.slot);
        set_package1(result.data.data.package);
        set_logo(result.data.data.url);
        set_qrcode(result.data.data.qrcode);

    }

    useEffect(() => {
        getBills();
    }, []);

    function dateFormat(value) {
        const dateString = value;
        const date = new Date(dateString);
        const options = { day: "numeric", month: "numeric", year: "2-digit" };
        return date.toLocaleDateString("en-GB", options);

    }

    function timeFormat(value) {
        const timeString = value;
        const time = new Date(`2000-01-01T${timeString}`);
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return time.toLocaleTimeString("en-US", options);
    }

    return (
        <>
            <div className="container mt-6 mb-7">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-7">
                        <div className="card">
                            <div className="card-body p-5">
                                <div className='d-flex justify-content-between'>
                                    <div className="col-md-6">
                                        <img src={logo} alt="" width={'80%'} />

                                    </div>
                                    <div className="col-md-6 text-end">
                                        <img src={`data:image/svg+xml;base64,${qrcode}`} alt="Converted" width={'50%'} />

                                    </div>
                                </div>
                                <h2>{username ?? ''},</h2>
                                <p className="fs-sm">
                                    payment receipt
                                </p>
                                <div className="border-top border-gray-200 pt-4 mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="text-muted mb-2">Membership Number</div>
                                            <strong>{unique_id ?? ''}</strong>
                                        </div>
                                        <div className="col-md-6 text-md-end">
                                            <div className="text-muted mb-2">Payment Date</div>
                                            <strong>{date ?? ''}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-top border-gray-200 mt-4 py-4">
                                    <div className="row">
                                        {username != null ?
                                            <div className="col-md-6">
                                                <div className="text-muted mb-2">Client</div>
                                                <strong>Name : {username}</strong>


                                                <p className="fs-sm">
                                                    Phone : {phone}
                                                </p>
                                                {/* <p className="text-purple">
                                                    Email : {email}
                                                </p> */}

                                                <p className="text-purple">
                                                    Package : {package1.package_name}
                                                </p>

                                                <p className="text-purple">
                                                    Package Start Date : {dateFormat(package1.package_start_date)}
                                                </p>

                                                <p className="text-purple">
                                                    Package End Date: {dateFormat(package1.package_end_date)}
                                                </p>

                                                <p className="text-purple">
                                                    Slot : {timeFormat(slot.slot_start_time) + ' - ' + timeFormat(slot.slot_end_time)}
                                                </p>
                                            </div>
                                            : <div className="col-md-6">
                                                <div className="text-muted mb-2">Expense</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <table className="table border-bottom border-gray-200 mt-3">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="fs-sm text-dark text-uppercase-bold-sm px-0"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-sm text-dark text-uppercase-bold-sm text-end px-0"
                                            >
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {array.map(function (item, key) {
                                            total_amount = parseInt(item.amount) + parseInt(total_amount);
                                            return (
                                                <tr key={key}>
                                                    {/* <td className="px-0">{item.description}</td> */}
                                                    <td className="px-0">{item.date}</td>
                                                    <td className="px-0 text-end px-0">{item.amount}</td>
                                                </tr>

                                            )

                                        })}

                                    </tbody>
                                </table>
                                <div className="mt-5">
                                    <div className="d-flex justify-content-end mt-3">
                                        <h5 className="me-3">Total:</h5>
                                        <h5 className="text-success">₹{total_amount ?? ''} Rupee</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MemberBill