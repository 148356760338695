/* eslint-disable jsx-a11y/anchor-is-valid */
// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Error from './components/pages/Error';
import Home from './components/pages/Home';

import MemberCreate from './components/pages/members/MemberCreate';
import MemberEdit from './components/pages/members/MemberEdit';

import PackageCreate from './components/pages/packages/PackageCreate';
import PackageEdit from './components/pages/packages/PackageEdit';

import SlotCreate from './components/pages/slot/CreateSlot';
import SlotEdit from './components/pages/slot/EditSlot';

import AttendanceCreate from './components/pages/attendance/AttendanceCreate';
import AttendanceExport from './components/pages/attendance/AttendanceExport';

import PlCreate from './components/pages/placcount/PlCreate';
import PlEdit from './components/pages/placcount/PlEdit';

import Login from './components/pages/Login';
import PrivateRoutes from './components/utils/PrivateRoutes';

import BillCreate from './components/pages/placcount/BillCreate';
import MemberBill from './components/pages/bill/MemberBill';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path='memberCreate' element={<MemberCreate />} />
          <Route path='memberEdit/:id?' element={<MemberEdit />} />

          {/* for packages */}
          <Route path='packageCreate' element={<PackageCreate />} />
          <Route path='packageEdit/:id?' element={<PackageEdit />} />


          {/* for packages */}
          <Route path='slotCreate' element={<SlotCreate />} />
          <Route path='slotEdit/:id?' element={<SlotEdit />} />

          {/* for Attendance */}
          <Route path='attendanceCreate' element={<AttendanceCreate />} />
          <Route path='attendanceExport' element={<AttendanceExport />} />

          {/* for Attendance */}
          <Route path='plCreate' element={<PlCreate />} />
          <Route path='plEdit/:id?' element={<PlEdit />} />
        </Route>

        <Route path='*' element={<Error />} />
        <Route path='MemberBill/:id?' element={<MemberBill />} />
        <Route path='BillCreate/:id?' element={<BillCreate />} />
        <Route path='login' element={<Login />} />

      </Routes>
    </div>
  );
}

export default App;
