import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { FaFileInvoice } from "react-icons/fa";
import axios from "axios";


function PlCreate() {
    const [search, set_search] = useState('');
    const [filtertransactions, set_filtertransactions] = useState([]);
    const [amount, set_amount] = useState('')
    const [date, set_date] = useState('')
    const [user_id, set_user_id] = useState('')
    const [url, set_url] = useState('')
    const [start_date, set_start_date] = useState(null)
    const [end_date, set_end_date] = useState(null)
    const [users, set_users] = useState([]);
    const [search_users, set_search_users] = useState([]);
    const [export_users, set_export_users] = useState([]);
    const [transactions, set_transactions] = useState([])
    const [transaction_type, set_transaction_type] = useState('')
    const [transaction_type_error, set_transaction_type_error] = useState('')
    const [amount_error, set_amount_error] = useState([])
    const [date_error, set_date_error] = useState([])
    const [user_error, set_user_error] = useState([])
    const [description, set_description] = useState([])
    const [export_user_id, set_export_user_id] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [exportTerm, setExportTerm] = useState('');

    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/placcount';
    const items = JSON.parse(localStorage.getItem('auth'));

    async function submitForm() {
        set_amount_error([]);
        set_date_error([]);
        set_transaction_type_error([]);

        // console.log(user_id);
        var result = await axios.post(baseURL, {
            transaction_type: transaction_type,
            user_id: user_id,
            amount: amount,
            date: date,
            description: description
        }, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });

        if (result.data.responseCode === 200) {
            // console.log('prateek');
            toast.success("Success!! Transaction has been Created.", { position: "bottom-right" });
            set_amount('');
            set_date('');
            set_transaction_type('');
            set_user_id('');
            set_description('');
            getTransactions();

        } else if (result.data.responseCode === 403) {
            // console.log(result.data.error);
            set_amount_error(result.data.error.amount);
            set_date_error(result.data.error.date);
            set_transaction_type_error(result.data.error.transaction_type);
        }
        else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
        }

    }

    async function Transaction_Delete(id) {
        // confirm("Want to delete?");
        var transactions = await axios.delete(baseURL + '/' + id, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        // console.log(transactions);
        if (transactions.data.responseCode === 200) {
            toast.success("Success!! Transaction has been Deleted.", { position: "bottom-right" });
            getTransactions();
        } else {
            transactions = [];
        }
        // if (confirm("Want to delete?") === true) {

        // }
    }

    async function getTransactions() {
        var transactions = await axios.get(baseURL, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });

        // console.log(transactions);
        if (transactions.data.responseCode === 200) {
            set_transactions(transactions.data.data.pl_accounts);
            set_filtertransactions(transactions.data.data.pl_accounts);
            set_users(transactions.data.data.users);
            set_search_users(transactions.data.data.users);
            set_url(transactions.data.data.url);
        } else {
            set_transactions([]);
        }
    }

    useEffect(() => {
        getTransactions();
    }, []);

    useEffect(() => {
        const result = transactions.filter(item => {
            return item.phone.toLowerCase().match(search.toLowerCase()) || item.unique_id.toLowerCase().match(search.toLowerCase());
        })
        set_filtertransactions(result);

        const result1 = users.filter(item => {
            return item.phone.toLowerCase().match(searchTerm.toLowerCase()) || item.unique_id.toLowerCase().match(searchTerm.toLowerCase());
        })
        // console.log(searchTerm, result1);
        set_search_users(result1);

        const result2 = users.filter(item => {
            return item.phone.toLowerCase().match(exportTerm.toLowerCase()) || item.unique_id.toLowerCase().match(exportTerm.toLowerCase());
        })
        set_export_users(result2);

    }, [search, searchTerm, transactions, users, exportTerm]);


    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: "4rem",
        },

        {
            name: 'Transaction Type',
            selector: row => row.transaction_type,
            sortable: true
        },

        {
            name: 'Member Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Member Phone',
            selector: row => row.phone,
            sortable: true

        },

        {
            name: 'MemberID',
            selector: row => row.unique_id,
            sortable: true

        },

        {
            name: 'Member Email',
            selector: row => row.email,
            // sortable:true

        },

        {
            name: 'Amount',
            selector: row => row.amount,
            // sortable:true

        },

        {
            name: 'Date',
            selector: row => row.date,
            // sortable:true

        },

        {
            name: 'Invoice',
            selector: row => <div>
                <a href={"/BillCreate/" + row.id} target='_blank' rel="noreferrer">
                    <button className='btn'><FaFileInvoice /></button>

                </a>
            </div>
            // sortable:true

        },

        {
            name: 'Action',
            selector: row =>

                <div>
                    <Link to={'/plEdit/' + row.id}>
                        <button className='btn'><TbEdit /></button>,
                    </Link>
                    <button className='btn' onClick={() => { Transaction_Delete(row.id) }}><MdDelete /></button>
                </div>

        },
    ];




    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Add Transactions</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Add Transactions</li>

                            {/* <a href="url"> Download</a> */}
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Add Transactions</h5>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Transaction Type</label>
                                        <div className="col-sm-10">
                                            <select id="" className="form-control" onChange={(e) => { set_transaction_type(e.target.value) }}>
                                                <option value="">Select Transaction</option>
                                                <option value="earning">Earning</option>
                                                <option value="expense">Expense</option>
                                            </select>

                                            {transaction_type_error ? (
                                                transaction_type_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>
                                    </div>

                                    {transaction_type === 'earning' ?


                                        <div className="row col-md-12 mb-3">
                                            <div className="col-md-2">
                                                <label htmlFor="inputText" className="form-label">User</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input
                                                    type="text"
                                                    placeholder="Search Phone/memberId"
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    className='form-control'
                                                />
                                                <select className='form-control' onClick={(e) => set_user_id(e.target.value) }>
                                                   
                                                    {search_users.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {'Name - ' + option.name + '/phone - ' + option.phone + '/MemberId - ' + option.unique_id + '/PackagePrice - ' + option.package_price + '/PackageName - ' + option.package_name + '/PackageStartDate - ' + option.package_start_date + '/PackageEndDate - ' + option.package_end_date}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>

                                        </div>

                                        : ''}

                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Amount</label>
                                        <div className="col-sm-10">
                                            <input type="number" className="form-control" placeholder="Amount" onChange={(e) => { set_amount(e.target.value) }} />
                                            {amount_error ? (
                                                amount_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Date</label>
                                        <div className="col-sm-10">
                                            <input type="date" className="form-control" placeholder="Price" onChange={(e) => { set_date(e.target.value) }} />
                                            {date_error ? (
                                                date_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Description</label>
                                        <div className="col-sm-10">
                                            <textarea id="editor" className='form-control' cols="30" rows="10" onChange={(e) => { set_description(e.target.value) }}></textarea>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Submit Button</label>
                                        <div className="col-sm-10">
                                            <button className="btn btn-primary" onClick={submitForm}>Submit Form</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-12 mb-4">
                            <CustomDatatable columns={columns} data={filtertransactions} title="Transactions Logs"
                                searchdata={<input type='text' placeholder='Search Keyword'
                                    className='w-25 form-control' value={search}
                                    onChange={(e) => set_search(e.target.value)}
                                />} />
                        </div>

                    </div>
                </section>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Export Transactions</h5>

                                    <div className="row col-md-12 mb-3">
                                        <div className="col-md-2">
                                            <label htmlFor="inputText" className="form-label">User</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                placeholder="Search/Phone/memberId"
                                                onChange={(e) => setExportTerm(e.target.value)}
                                                className='form-control'
                                            />
                                            <select className='form-control' onClick={(e) => set_export_user_id(e.target.value)}>
                                                {export_users.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {'Name - ' + option.name + '/phone - ' + option.phone + '/MemberId - ' + option.unique_id +'/PackageName - ' + option.package_name + '/PackageStartDate - ' + option.package_start_date + '/PackageEndDate - ' + option.package_end_date}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>

                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Start Date</label>
                                        <div className="col-sm-10">
                                            <input type="date" className="form-control" placeholder="Start Date" onChange={(e) => { set_start_date(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">End Date</label>
                                        <div className="col-sm-10">
                                            <input type="date" className="form-control" placeholder="End Date" onChange={(e) => { set_end_date(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Export Button</label>
                                        <div className="col-sm-10">

                                            <a href={url + "/api/v1/plAccount/export?export_user_id=" + export_user_id + "&start_date=" + start_date + "&end_date=" + end_date}>
                                                <button className="btn btn-primary">Export</button>
                                            </a>
                                            {/* <a href={ baseURL + '/export?user_id=' + export_user_id + '&start_date=' + start_date + '&end_date=' + end_date}>
                                                <button className="btn btn-primary">Export</button>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main >
            <ToastContainer />

        </>
    )
}

export default PlCreate;