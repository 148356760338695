import React from "react";
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { FcComments } from "react-icons/fc";

function AttendanceCreate() {
    const [search, set_search] = useState('');
    const [filtermembers, set_filtermembers] = useState([]);
    const [members, set_members] = useState([]);
    const [attendance, set_attendance] = useState([]);

    const items = JSON.parse(localStorage.getItem('auth'));
    const options = { day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'Asia/Kolkata' };
    const currentDate = new Date().toLocaleDateString('en-IN', options);

    async function handleCheckboxChange(id, status, slot_id) {
        // console.log(id, status);
        var result = await axios.post(baseURL, {
            member_id: id,
            slot_id: slot_id,
            attendance: status
        }, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });

        if (result.data.responseCode === 200) {
            toast.success("Success!! Attendance has been Updated.", { position: "bottom-right" });
            getAttendance();

        } else if (result.data.responseCode === 403) {
            console.log(result.data);
        }
        else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
        }
    };

    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/attendance';


    async function submitComment(member_id, input, slot_id) {
        console.log(member_id, input);
        var result = await axios.post(baseURL, {
            member_id: member_id,
            slot_id: slot_id,
            comment: input
        });

        // console.log(result);
        if (result.data.responseCode === 200) {
            toast.success("Success!! Comment has been Updated.", { position: "bottom-right" });
            getAttendance();

        } else if (result.data.responseCode === 403) {
            console.log(result.data.responseCode);
        }
        else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
        }

    }

    async function getAttendance() {
        var attendance = await axios.get(baseURL, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        console.log(attendance);
        if (attendance.data.responseCode === 200) {
            set_members(attendance.data.data.members);
            set_filtermembers(attendance.data.data.members);
            // const intArray = attendance.data.data.attendance.map((str) => parseInt(str));
            set_attendance(attendance.data.data.attendance);
        }
    }
    console.log(attendance);

    // console.log(filtermembers);

    useEffect(() => {
        getAttendance();
    }, []);

    useEffect(() => {
        const result = members.filter(item => {
            return item.phone.toLowerCase().match(search.toLowerCase()) || item.unique_id.toLowerCase().match(search.toLowerCase());
        })
        set_filtermembers(result);
    }, [search]);

    // console.log(attendance, attendance.includes(1));

    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: "4rem",
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },

        {
            name: 'Email',
            selector: row => row.email,
            // sortable:true

        },
        
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true

        },

        {
            name: 'MemberID',
            selector: row => row.unique_id,
            // sortable:true

        },

        {
            name: 'Absent',
            selector: row =>
                <input
                    type="radio"
                    name={"attendance" + row.member_id}
                    checked={!attendance.includes(row.member_id)}
                    onChange={() => handleCheckboxChange(row.member_id, 0, row.slot_id)}
                />



        },


        {
            name: 'Present',
            selector: row =>

                <div>

                    <input
                        type="radio"
                        name={"attendance" + row.member_id}
                        checked={attendance.includes(row.member_id)}
                        onChange={() => handleCheckboxChange(row.member_id, 1, row.slot_id)}
                    />
                    {/* {console.log(attendance.includes(row.id), 'attendance')} */}

                </div>

        },


        {
            name: 'Comment',
            selector: row =>
            <div className="row">
                <input type="text" onBlur={(e) =>  submitComment(row.member_id, e.target.value, row.slot_id)} className="form-control" placeholder={row.comment} />
               

            </div>
            // sortable:true

        }
    ];

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1> Attendance</h1>
                   
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
                            <li className="breadcrumb-item active"> Attendance</li>
                        </ol>
                    </nav>
                    <h4> Date : {currentDate} </h4>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <CustomDatatable columns={columns} data={filtermembers} title="Attendance"
                                searchdata={<input type='text' placeholder='Search Keyword'
                                    className='w-25 form-control' value={search}
                                    onChange={(e) => set_search(e.target.value)}
                                />} />
                        </div>

                    </div>
                </section>

            </main>
            <ToastContainer />

        </>
    )
}

export default AttendanceCreate;