import React, { useEffect, useState } from "react"
import { useNavigate, NavLink } from 'react-router-dom';
import { RiLogoutBoxRLine } from "react-icons/ri";
import axios from "axios";


function Header() {

    const navigate = useNavigate();
    const auth = JSON.parse(localStorage.getItem('auth'));
    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/headerIcons';

    const [icon, set_icon] = useState();

    // console.log(auth.user);

    function Logout() {
        localStorage.removeItem('auth');
        navigate('/login');
    }

    useEffect(() => {
        getHeader();
    }, []);

    async function getHeader() {
        var result = await axios.get(baseURL);
        console.log(result);
        if (result.data.responseCode === 200) {
            set_icon(result.data.data.icon);
        } else {

        }
    }
    return (
        <>

            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <NavLink to="" className="logo d-flex align-items-center">
                        <img src={icon} alt="" width={'35%'} height={'100%'} />
                        {/* <span className="d-none d-lg-block">SwimmingPool</span> */}
                    </NavLink>
                    <i className="bi bi-list toggle-sidebar-btn" id="toggleSidebarBtn"></i>
                </div>


                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="www.google.com" data-bs-toggle="dropdown">
                                {/* <img src="backend/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                <span className="d-none d-md-block dropdown-toggle ps-2">{auth.user.name ?? ""}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{auth.user.name ?? ""}</h6>
                                    <span>{auth.user.role ?? ""}</span>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <button className="form-control" onClick={Logout}>
                                        <RiLogoutBoxRLine />
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>
        </>
    )
}

export default Header;