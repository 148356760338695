import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function PlEdit() {

  const [amount, set_amount] = useState('')
  const [date, set_date] = useState('')
  const [user_id, set_user_id] = useState('')
  const [users, set_users] = useState([])
  const [amount_error, set_amount_error] = useState([])
  const [date_error, set_date_error] = useState([])
  const [user_error, set_user_error] = useState([])
  const [search_users, set_search_users] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [description, set_description] = useState([])
  const [transaction_type, set_transaction_type] = useState('')
  const [transaction_type_error, set_transaction_type_error] = useState('')

  var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/placcount';
  const items = JSON.parse(localStorage.getItem('auth'));

  const params = useParams();

  async function submitForm(e) {
    e.preventDefault();
    set_amount_error([]);
    set_date_error([]);
    set_user_error([]);

    var result = await axios.put(baseURL + '/' + params.id, {
      user_id: user_id,
      amount: amount,
      date: date
    }, {
      headers: {
        Authorization: 'Bearer ' + items.token
      }
    });

    if (result.data.responseCode === 200) {
      toast.success("Success!! Transaction has been Updated.", { position: "bottom-right" });
      set_amount('');
      set_date('');
      showTransaction();

    } else if (result.data.responseCode === 403) {
      set_amount_error(result.data.error.amount);
      set_date_error(result.data.error.date);
      set_user_error(result.data.error.user_id);
      set_transaction_type_error(result.data.error.transaction_type);

    }
    else {
      toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
    }
  }

  useEffect(() => {
    showTransaction();
  }, []);


  useEffect(() => {

    const result1 = users.filter(item => {
        return item.phone.toLowerCase().match(searchTerm.toLowerCase()) || item.unique_id.toLowerCase().match(searchTerm.toLowerCase());
    })
    // console.log(searchTerm, result1);
    set_search_users(result1);

}, [ searchTerm, users]);

  async function showTransaction() {
    var result = await axios.get(baseURL + '/' + params.id, {
      headers: {
        Authorization: 'Bearer ' + items.token
      }
    });

    console.log(result);
    if (result.data.responseCode === 200) {
      set_transaction_type(result.data.data.pl_account.transaction_type);
      set_user_id(result.data.data.pl_account.user_id);
      set_amount(result.data.data.pl_account.amount);
      set_date(result.data.data.pl_account.date);
      set_description(result.data.data.pl_account.description);
      set_users(result.data.data.users);
    } else if (result.data.responseCode === 403) {
      // console.log(result.data.error);
      set_transaction_type_error(result.data.error.transaction_type);
      set_user_error(result.data.error.user_id);
      set_amount_error(result.data.error.amount);
      set_date_error(result.data.error.date);

    }
    else {
      toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
    }

  }

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Transactions</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
              <li className="breadcrumb-item active">Edit Transactions</li>

              {/* <a href="url"> Download</a> */}
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Edit Transactions</h5>
                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Transaction Type</label>
                    <div className="col-sm-10">
                      <select id="" className="form-control" value={transaction_type} onChange={(e) => { set_transaction_type(e.target.value) }}>
                        <option value="earning">Earning</option>
                        <option value="expense">Expense</option>
                      </select>

                      {transaction_type_error ? (
                        transaction_type_error.map((item, key) => {
                          // console.log(item);
                          return <li className="text-danger fw-bold" key={key}>{item}</li>
                        })
                      ) : ""}
                    </div>
                  </div>

                  {transaction_type === 'earning' ?


                    <div className="row col-md-12 mb-3">
                      <div className="col-md-2">
                        <label htmlFor="inputText" className="form-label">User</label>
                      </div>
                      <div className="col-md-10">
                        <input
                          type="text"
                          placeholder="Search Phone/memberId"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className='form-control'
                        />
                        <select className='form-control' onClick={(e) => set_user_id(e.target.value)}>

                          {search_users.map((option) => (
                            <option key={option.id} value={option.id}>
                              {'Name - ' + option.name + '/phone - ' + option.phone + '/MemberId - ' + option.unique_id + '/PackagePrice - ' + option.package_price + '/PackageName - ' + option.package_name + '/PackageStartDate - ' + option.package_start_date + '/PackageEndDate - ' + option.package_end_date}
                            </option>
                          ))}
                        </select>

                      </div>

                    </div>

                    : ''}

                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Amount</label>
                    <div className="col-sm-10">
                      <input type="number" className="form-control" placeholder="Amount" value={amount} onChange={(e) => { set_amount(e.target.value) }} />
                      {amount_error ? (
                        amount_error.map((item, key) => {
                          // console.log(item);
                          return <li className="text-danger fw-bold" key={key}>{item}</li>
                        })
                      ) : ""}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Date</label>
                    <div className="col-sm-10">
                      <input type="date" className="form-control" placeholder="Date" value={date} onChange={(e) => { set_date(e.target.value) }} />
                      {date_error ? (
                        date_error.map((item, key) => {
                          // console.log(item);
                          return <li className="text-danger fw-bold" key={key}>{item}</li>
                        })
                      ) : ""}
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-10">
                      <textarea id="editor" className='form-control' value={description} cols="30" rows="10" onChange={(e) => { set_description(e.target.value) }}></textarea>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">Submit Button</label>
                    <div className="col-sm-10">
                      <button className="btn btn-primary" onClick={submitForm}>Submit Form</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>

      </main >
      <ToastContainer />

    </>
  )
}

export default PlEdit