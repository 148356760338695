/* eslint-disable no-dupe-keys */
import React from "react";
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbEdit } from "react-icons/tb";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import axios from "axios";
import { FaDownload } from "react-icons/fa";

function MemberCreate() {
    const [search, set_search] = useState('');
    const [filtermembers, set_filtermembers] = useState([]);
    const [members, set_members] = useState([]);
    const [slots, set_slots] = useState([]);
    const [packages, set_packages] = useState([]);
    const [name, set_name] = useState('');
    const [g_name, set_g_name] = useState('');
    const [package_start_date, set_package_start_date] = useState('');

    const [email, set_email] = useState('');
    const [phone, set_phone] = useState('');
    const [emergency_phone, set_emergency_phone] = useState('');
    const [dob, set_dob] = useState('');
    const [image, set_image] = useState('');
    const [url, set_url] = useState('');
    const [document, set_document] = useState('');
    const [package_id, set_package_id] = useState('');
    const [status, set_status] = useState('');
    const [slot_id, set_slot_id] = useState('');
    const [address, set_address] = useState('');
    const [name_error, set_name_error] = useState([]);
    const [g_name_error, set_g_name_error] = useState([]);
    const [email_error, set_email_error] = useState([]);
    const [package_id_error, set_package_id_error] = useState([]);
    const [slot_id_error, set_slot_id_error] = useState([]);
    const [phone_error, set_phone_error] = useState([]);
    const [status_error, set_status_error] = useState([]);
    const [image_error, set_image_error] = useState([])
    const [document_error, set_document_error] = useState([])
    const [address_error, set_address_error] = useState([]);
    const [emergency_phone_error, set_emergency_phone_error] = useState([]);
    const [dob_error, set_dob_error] = useState([]);
    const [package_start_date_error, set_package_start_date_error] = useState([]);

    const [base64Images, setBase64Images] = useState([]);

    const items = JSON.parse(localStorage.getItem('auth'));
    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/members';

    useEffect(() => {
        getMembers();
    }, []);


    useEffect(() => {
        const result = members.filter(item => {
            // console.log(item.name);
            return item.phone.toLowerCase().match(search.toLowerCase()) || item.unique_id.toLowerCase().match(search.toLowerCase());
        })
        set_filtermembers(result);
    }, [search]);

    async function submitForm() {

        set_name_error([]);
        set_phone_error([]);
        set_email_error([]);
        set_status_error([]);
        set_package_id_error([]);
        set_slot_id_error([]);
        set_address_error([]);
        set_g_name_error([]);

        console.log(baseURL);
        var result = await axios.post(baseURL, {
            name: name,
            g_name: g_name,
            phone: phone,
            email: email,
            image, image,
            status: status,
            emergency_phone: emergency_phone,
            dob: dob,
            document: base64Images,
            package_id: package_id,
            package_start_date: package_start_date,
            slot_id: slot_id,
            address: address
        }, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });

        // console.log(result);

        if (result.data.responseCode === 200) {
            toast.success("Success!! Package has been Created.", { position: "bottom-right" });
            set_name("");
            set_g_name("");
            set_phone("");
            set_email("");
            set_status("");
            set_address("");
            set_package_id("");
            set_dob('');
            set_emergency_phone('');
            set_slot_id("");
            getMembers();

        } else if (result.data.responseCode === 403) {
            set_name_error(result.data.error.name);
            set_g_name_error(result.data.error.g_name);
            set_phone_error(result.data.error.phone);
            set_email_error(result.data.error.email);
            set_status_error(result.data.error.status);
            set_address_error(result.data.error.address);
            set_package_id_error(result.data.error.package_id);
            set_dob_error(result.data.error.dob);
            set_image_error(result.data.error.image);
            set_document_error(result.data.error.document);
            set_emergency_phone_error(result.data.error.emergency_phone);
            set_slot_id_error(result.data.error.slot_id);
        }
        else {
            // console.log(result);
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });

        }
    }

    async function getMembers() {
        var result = await axios.get(baseURL, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        console.log(result);
        if (result.data.responseCode === 200) {
            set_members(result.data.data.members);
            set_packages(result.data.data.packages);
            set_slots(result.data.data.slots);
            set_filtermembers(result.data.data.members);
            set_url(result.data.data.url);
        } else {
            set_packages([]);
        }
    }

    function onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createImage(files[0]);
    }

    function createImage(file) {
        // var image1 = new Image();
        var reader = new FileReader();

        reader.onload = (e) => {
            set_image(e.target.result);
            console.log(e.target.result);
        };
        reader.readAsDataURL(file);
    }

    function onDocumentChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createDocument(files[0]);
    }

    function createDocument(file) {
        // var image1 = new Image();
        var reader = new FileReader();

        reader.onload = (e) => {
            set_document(e.target.result);
        };
        reader.readAsDataURL(file);
    }

    // function removeImage(e) {
    //     this.image = '';
    // }

    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: "4rem",
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'phone',
            selector: row => row.phone,
            sortable: true

        },

        {
            name: 'email',
            selector: row => row.email,
            sortable: true

        },

        {
            name: 'MemberID',
            selector: row => row.unique_id,
            sortable: true

        },

        {
            name: 'Status',
            selector: row => row.status === "1" ? 'Active' : 'Inactive',
            // sortable:true

        },

        {
            name: 'Action',
            selector: row =>
                <div>
                    <Link to={'/memberEdit/' + row.id}>
                        <button className='btn'><TbEdit /></button>
                    </Link>

                    <a href={url + "/generateId/" + row.id} target="_blank" rel="noreferrer">
                        <button className='btn'><MdOutlineQrCodeScanner /></button>
                    </a>
                    <a href={url + "/qrcodeDownload/" + row.id} rel="noreferrer">
                        <button className='btn'><FaDownload /></button>
                    </a>
                </div>




            // sortable:true

        },
        // {
        //     name: 'Action Edit',
        //     selector: row =>
        //     <a href={('generateId/' + row.id)}>
        //         <button className="btn btn-primary btn-sm">Generate ID</button>
        //     </a>
        //     // sortable:true

        // }
        // log
    ];

    const handleImageInputChange = (e) => {
        const files = e.target.files;
        const imageFiles = Array.from(files);

        Promise.all(
            imageFiles.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        resolve(event.target.result);
                    };

                    reader.readAsDataURL(file);
                });
            })
        ).then((base64Strings) => {
            setBase64Images(base64Strings);
        });
    };

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Members Create</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Members Create</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <h5 className="card-title">Add Members Create</h5>

                                    <div className="row">
                                        <div className="col-6">
                                            <label htmlFor="inputNanme4" className="form-label">Name</label>
                                            <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => { set_name(e.target.value) }} id="inputNanme4" />
                                            {name_error ? (
                                                name_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}

                                        </div>

                                        <div className="col-6">
                                            <label htmlFor="inputNanme4" className="form-label">Guardian Name</label>
                                            <input type="text" className="form-control" placeholder="Guardian Name" value={g_name} onChange={(e) => { set_g_name(e.target.value) }} id="inputNanme4" />
                                            {g_name_error ? (
                                                g_name_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}

                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <label htmlFor="inputEmail4" className="form-label">Email</label>
                                            <input type="email" className="form-control" placeholder="xxxx-@gmail" value={email} onChange={(e) => { set_email(e.target.value) }} id="inputEmail4" />
                                            {email_error ? (
                                                email_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>

                                        <div className="col-6">
                                            <label htmlFor="inputEmail4" className="form-label">Package Start Date</label>
                                            <input type="date" className="form-control" placeholder="xxxx-@gmail" value={package_start_date} onChange={(e) => { set_package_start_date(e.target.value) }} id="inputEmail4" />
                                            {package_start_date_error ? (
                                                package_start_date_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>

                                    </div>
                                    <div className="col-12 row">
                                        <div className="col-md-4">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <input type="text" className="form-control" placeholder="xxxx-9999" value={phone} id="phone" onChange={(e) => { set_phone(e.target.value) }} />
                                            {phone_error ? (
                                                phone_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="phone" className="form-label">Emergency Phone</label>
                                            <input type="text" placeholder="xxxx-9999" className="form-control" value={emergency_phone} id="phone" onChange={(e) => { set_emergency_phone(e.target.value) }} />
                                            {emergency_phone_error ? (
                                                emergency_phone_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="phone" className="form-label">Date of Birth</label>
                                            <input type="date" className="form-control" value={dob} id="phone" onChange={(e) => { set_dob(e.target.value) }} />
                                            {dob_error ? (
                                                dob_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">Status</label>
                                        <select className="form-control form-select" value={status} onChange={(e) => { set_status(e.target.value) }}>
                                            <option value="">Select</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        {status_error ? (
                                            status_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>

                                    <div className="row">

                                        <div className="col-6">
                                            <label htmlFor="phone" className="form-label">Image</label>
                                            <input type="file" onChange={onFileChange} className="form-control" id="phone" />
                                            {image_error ? (
                                                image_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>

                                        <div className="col-6">
                                            <label htmlFor="phone" className="form-label">Document</label>
                                            <div>
                                                <input type="file" className="form-control" multiple onChange={handleImageInputChange} />
                                                {base64Images.map((base64String, index) => (
                                                    <img width={'20%'} key={index} src={base64String} alt={`Image ${index}`} />
                                                ))}
                                            </div>
                                            {document_error ? (
                                                document_error.map((item, key) => {
                                                    // console.log(item);
                                                    return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                })
                                            ) : ""}
                                        </div>

                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">Slot</label>
                                        <select name="slot_id" className="form-control" value={slot_id} onChange={(e) => { set_slot_id(e.target.value) }}>
                                            <option value="">Select</option>
                                            {slots.map((item, key) => {
                                                return <option value={item.id} key={key}>{item.name}</option>
                                            })}

                                        </select>
                                        {slot_id_error ? (
                                            slot_id_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">Package</label>
                                        <select name="package_id" className="form-control" value={package_id} onChange={(e) => { set_package_id(e.target.value) }}>
                                            <option value="">Select</option>
                                            {packages.map((item, key) => {
                                                return <option value={item.id} key={key}>{item.name}</option>
                                            })}

                                        </select>
                                        {package_id_error ? (
                                            package_id_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">Address</label>
                                        <textarea name="address" className="form-control" value={address} onChange={(e) => { set_address(e.target.value) }} id="" cols="30" rows="10"></textarea>
                                        {address_error ? (
                                            address_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>
                                    <div className="text-center">
                                        <button onClick={submitForm} className="btn btn-primary">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <CustomDatatable columns={columns} data={filtermembers} title="Members Logs"
                                searchdata={<input type='text' placeholder='Search Keyword'
                                    className='w-25 form-control' value={search}
                                    onChange={(e) => set_search(e.target.value)}
                                />} />

                        </div>
                    </div>
                </section>
            </main>
            <ToastContainer />

        </>
    )
}

export default MemberCreate;