import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function BillCreate() {

    const [username, set_username] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    const [date, set_date] = useState('');
    const [qrcode, set_qrcode] = useState('');
    const [unique_id, set_unique_id] = useState('');
    const [slot, set_slot] = useState('');
    const [package1, set_package1] = useState('');
    const [bill_id, set_bill_id] = useState('');
    const [amount, set_amount] = useState('');
    const [description, set_description] = useState('');
    const [logo, set_logo] = useState('');

    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/placcount';
    const items = JSON.parse(localStorage.getItem('auth'));

    const params = useParams();

    useEffect(() => {
        showTransaction();
    }, []);

    async function showTransaction() {
        var transactions = await axios.get(baseURL + '/' + params.id, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        console.log(transactions.data.data, 'prateek');
        if (transactions.data.responseCode == 200) {
            set_username(transactions.data.data.pl_account.name);
            set_phone(transactions.data.data.pl_account.phone);
            set_email(transactions.data.data.pl_account.email);
            set_amount(transactions.data.data.pl_account.amount);
            set_unique_id(transactions.data.data.pl_account.unique_id);
            set_date(transactions.data.data.pl_account.date);
            set_description(transactions.data.data.pl_account.description);
            set_bill_id(transactions.data.data.pl_account.id);
            set_slot(transactions.data.data.slot);
            set_package1(transactions.data.data.package);
            set_logo(transactions.data.data.url);
            set_qrcode(transactions.data.data.qrcode);
            // console.log(bill_id);
        } else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });
        }

    }

    const convertBase64ToImage = (base64String) => {
        return `data:image/jpeg;base64,${base64String}`;
    };

    function dateFormat(value) {
        const dateString = value;
        const date = new Date(dateString);
        const options = { day: "numeric", month: "numeric", year: "2-digit" };
        return date.toLocaleDateString("en-GB", options);

    }

    function timeFormat(value) {
        const timeString = value;
        const time = new Date(`2000-01-01T${timeString}`);
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return time.toLocaleTimeString("en-US", options);
    }

    return (
        <>
            <div className="container mt-6 mb-7">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-7">
                        <div className="card">
                            <div className="card-body p-5">
                                <div className='d-flex justify-content-between'>
                                    <div className="col-md-6">
                                        <img src={logo} alt="" width={'80%'} />

                                    </div>
                                    <div className="col-md-6 text-end">
                                        <img src={`data:image/svg+xml;base64,${qrcode}`} alt="Converted" width={'50%'} />

                                    </div>
                                </div>
                                <h2>{username ?? 'Expense'},</h2>
                                <p className="fs-sm">
                                    This is the receipt for a payment of <strong>₹{amount}</strong> (Rupee)
                                </p>
                                <div className="border-top border-gray-200 pt-4 mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="text-muted mb-2">Transaction ID.</div>
                                            <strong>#00{bill_id}</strong>
                                        </div>
                                        <div className="col-md-6 text-md-end">
                                            <div className="text-muted mb-2">Payment Date</div>
                                            <strong>{date}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-top border-gray-200 mt-4 py-4">
                                    <div className="row">
                                        {username != null ?
                                            <div className="col-md-6">
                                                <div className="text-muted mb-2">Client</div>
                                                <strong>Name : {username}</strong>


                                                <p className="fs-sm">
                                                    Membership Number : {unique_id}
                                                </p>

                                                <p className="fs-sm">
                                                    Phone : {phone}
                                                </p>
                                                {/* <p className="text-purple">
                                                    Email : {email}
                                                </p> */}

                                                <p className="text-purple">
                                                    Package : {package1.package_name}
                                                </p>


                                                <p className="text-purple">
                                                    Package Start Date : {dateFormat(package1.package_start_date)}
                                                </p>

                                                <p className="text-purple">
                                                    Package End Date: {dateFormat(package1.package_end_date)}
                                                </p>

                                                <p className="text-purple">
                                                    Slot : {timeFormat(slot.slot_start_time) + ' - ' + timeFormat(slot.slot_end_time)}
                                                </p>
                                            </div>
                                            : <div className="col-md-6">
                                                <div className="text-muted mb-2">Expense</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <table className="table border-bottom border-gray-200 mt-3">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="fs-sm text-dark text-uppercase-bold-sm px-0"
                                            >
                                                Description
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {description}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                <div className="mt-5">
                                    <div className="d-flex justify-content-end mt-3">
                                        <h5 className="me-3">Total:</h5>
                                        <h5 className="text-success">₹{amount} Rupee</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default BillCreate