import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


function Login() {
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [email_error, set_email_error] = useState('');
    const [password_error, set_password_error] = useState('');

    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/login';
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            navigate('/');
        }
    },[]);

    async function onSubmit() {
        // console.log(email, password);
        var result = await axios.post(baseURL, {
            email: email,
            password: password
        });

        
        if (result.data.responseCode === 200) {
            localStorage.setItem('auth', JSON.stringify(result.data.data));
            navigate('/');

        } else if (result.data.responseCode === 403) {
            set_email_error(result.data.error.email);
            set_password_error(result.data.error.password);
        }
        else if (result.data.responseCode === 401) {
            // console.log(result.data);
            toast.error("Credentials Does Not Match or Unauthenticated", { position: "bottom-right" });
        }
        else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });

        }

    }

    return (
        <>
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                            <div className="d-flex justify-content-center py-4">
                                <a href="index.html" className="logo d-flex align-items-center w-auto">
                                    <img src="assets/img/logo.png" alt="" />
                                    <span className="d-none d-lg-block">NiceAdmin</span>
                                </a>
                            </div>

                            <div className="card mb-3">

                                <div className="card-body">

                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                        <p className="text-center small">Enter your username & password to login</p>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="yourUsername" className="form-label">UserEmail</label>
                                        <div className="input-group has-validation">
                                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                                            <input type="text" name="username" className="form-control" onChange={(e) => { set_email(e.target.value) }} id="yourUsername" required />
                                            <div className="invalid-feedback">Please enter your username.</div>
                                        </div>
                                        {email_error ? (
                                            email_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="yourPassword" className="form-label">Password</label>
                                        <input type="password" name="password" className="form-control" onChange={(e) => { set_password(e.target.value) }} id="yourPassword" required />
                                        <div className="invalid-feedback">Please enter your password!</div>
                                        {password_error ? (
                                            password_error.map((item, key) => {
                                                // console.log(item);
                                                return <li className="text-danger fw-bold" key={key}>{item}</li>
                                            })
                                        ) : ""}
                                    </div>

                                    {/* <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                            </div>
                                        </div> */}

                                    <div className="col-12">
                                        <button className="btn btn-primary w-100" onClick={onSubmit}>Login</button>
                                    </div>

                                </div>
                            </div>

                            <div className="credits">

                                Designed by <a target="_blank" rel="noreferrer" href="https://stunningsolutions.in/">Stunning Solutions</a>
                            </div>

                        </div>
                    </div>
                </div>
            <ToastContainer />


            </section>
        </>
    )
}

export default Login;