import React, { useState, useEffect } from 'react'
import CustomDatatable from '../FrontendComponents/CustomDatatable';
import 'react-toastify/dist/ReactToastify.css';
import { FaFileInvoice } from "react-icons/fa";
import axios from 'axios';

function Home() {

  const [search, set_search] = useState('');
  const [filtermembers, set_filtermembers] = useState([]);
  const [members, set_members] = useState([]);
  const [total_members, set_total_members] = useState('')
  const [total_packages, set_total_packages] = useState('')
  const [total_slots, set_total_slots] = useState('')
  // const [transactions, set_transactions] = useState([]);
  const [url, set_url] = useState([]);

  var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/home';
  const items = JSON.parse(localStorage.getItem('auth'));

  useEffect(() => {
    getHome();
  }, []);

  useEffect(() => {
    const result = members.filter(item => {
      return item.phone.toLowerCase().match(search.toLowerCase()) || item.unique_id.toLowerCase().match(search.toLowerCase());
    })
    // console.log(result);
    set_filtermembers(result);
    console.log(result);
  }, [search]);

  async function getHome() {
    var result = await axios.get(baseURL, {
      headers: {
        Authorization: 'Bearer ' + items.token
      }
    });
    // console.log(result);
    set_members(result.data.data.members);
    set_filtermembers(result.data.data.members);
    set_total_members(result.data.data.total_members);
    set_total_packages(result.data.data.total_packages);
    set_total_slots(result.data.data.total_slots);
    set_url(result.data.data.url);
  }



  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: "4rem",
    },

    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },

    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true
    },

    {
      name: 'MemberID',
      selector: row => row.unique_id,
      sortable: true
    },

    // {
    //   name: 'Email',
    //   selector: row => row.email,
    //   sortable: true
    // },

    {
      name: 'Package',
      selector: row => row.package_name,
      sortable: true

    },

    {
      name: 'Package Start Date',
      selector: row => row.package_start_date,
      // sortable:true

    },

    {
      name: 'Package End Date',
      selector: row => row.package_end_date,
      // sortable:true

    },

    {
      name: 'Payment',
      selector: row => row.package_status === "1" ? 'Paid' : 'Due',
      // sortable:true

    },


    {
      name: 'Timings',
      selector: row => row.slot_start_time + '-' + row.slot_end_time,
      // sortable:true

    },

    {
      name: 'Invoice',
      selector: row => <div>
        <a href={"/MemberBill/" + row.member_id} target='_blank' rel="noreferrer">
          <button className='btn'><FaFileInvoice /></button>

        </a>
      </div>
      // sortable:true

    }
  ];

  return (
    <>
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">

            <div className="col-lg-12">
              <div className="row">

                <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">

                    <div className="card-body">
                      <h5 className="card-title">Total Active Members <span></span></h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-person"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{total_members ?? 0}</h6>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <div className="col-xxl-4 col-md-6">
                  <div className="card info-card revenue-card">

                    <div className="card-body">
                      <h5 className="card-title">Total Packages <span></span></h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-person"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{total_packages ?? 0}</h6>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-xxl-4 col-xl-12">

                  <div className="card info-card customers-card">

                    <div className="card-body">
                      <h5 className="card-title">Total Slots <span></span></h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-cart"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{total_slots}</h6>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>


              </div>
            </div>


          </div>
        </section>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <CustomDatatable columns={columns} data={filtermembers} title="Members Logs"
                searchdata={<input type='text' placeholder='Search Keyword'
                  className='w-25 form-control' value={search}
                  onChange={(e) => set_search(e.target.value)}
                />} />

            </div>

          </div>
        </section>
      </main >
    </>
  )
}

export default Home;