import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';


function AttendanceExport() {

  const [url, set_url] = useState('')
  // const [query, set_query] = useState('react')
  const [export_user_id, set_export_user_id] = useState('')
  const [start_date, set_start_date] = useState("")
  const [end_date, set_end_date] = useState("")
  const [users, set_users] = useState([])
  const [search_users, set_search_users] = useState([]);
  const [export_users, set_export_users] = useState([]);
  const [exportTerm, setExportTerm] = useState('');


  var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/attendance1/export';
  const items = JSON.parse(localStorage.getItem('auth'));

  async function getTransactions() {
    var transactions = await axios.get(baseURL, {
      headers: {
        Authorization: 'Bearer ' + items.token
      }
    });
    if (transactions.data.responseCode === 200) {
      console.log(transactions);
      set_users(transactions.data.data.members);
      set_url(transactions.data.data.url);
      set_search_users(transactions.data.data.members);

    }
  }

  // console.log(users);

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {

    const result2 = users.filter(item => {
      return item.phone.toLowerCase().match(exportTerm.toLowerCase()) || item.unique_id.toLowerCase().match(exportTerm.toLowerCase());
    })
    set_export_users(result2);

  }, [users, exportTerm]);

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1> Attendance</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
              <li className="breadcrumb-item active"> Attendance</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Export Transactions</h5>

                  <div className="row mb-3">
                    <div className="row col-md-12 mb-3">
                      <div className="col-md-2">
                        <label htmlFor="inputText" className="form-label">User</label>
                      </div>
                      <div className="col-md-10">
                        <input
                          type="text"
                          placeholder="Search Username/Phone/memberId"
                          onChange={(e) => setExportTerm(e.target.value)}
                          className='form-control'
                        />
                        <select className='form-control' name='user_id'>
                          {export_users.map((option) => (
                            <option key={option.id} value={option.id}>
                              {'Name - ' + option.name + '/phone - ' + option.phone + '/MemberId - ' + option.unique_id}
                            </option>
                          ))}
                        </select>

                      </div>

                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Start Date</label>
                    <div className="col-sm-10">
                      <input type="date" className="form-control" placeholder="Start Date" onChange={(e) => { set_start_date(e.target.value) }} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">End Date</label>
                    <div className="col-sm-10">
                      <input type="date" className="form-control" placeholder="End Date" onChange={(e) => { set_end_date(e.target.value) }} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">Export Button</label>
                    <div className="col-sm-10">

                      <a href={url + "/api/v1/attendance2/export?user_id=" + export_user_id + "&start_date=" + start_date + "&end_date=" + end_date}>
                        <button className="btn btn-primary">Export</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>



      </main>
      <ToastContainer />

    </>

  )
}

export default AttendanceExport