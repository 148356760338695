import React from 'react'
import { Navigate } from 'react-router-dom';
import App1 from '../layouts/App1';

const PrivateRoutes = () => {
    let auth = localStorage.getItem('auth');

  return (
    auth ? <App1/> : <Navigate to="/login"/>
  )
}

export default PrivateRoutes