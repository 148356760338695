import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CustomDatatable from '../../FrontendComponents/CustomDatatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { TbEdit } from "react-icons/tb";
import './style.css';


function SlotCreate() {
    const [search, set_search] = useState('');
    const [filterslots, set_filterslots] = useState([]);
    const [slots, set_slots] = useState([]);
    const [name, set_name] = useState('');
    const [cycle, set_cycle] = useState([]);
    const [start_time, set_start_time] = useState('');
    const [end_time, set_end_time] = useState('');
    const [status, set_status] = useState('');
    const [show_days, set_show_days] = useState(false);
    const [name_error, set_name_error] = useState([]);
    const [start_time_error, set_start_time_error] = useState([]);
    const [end_time_error, set_end_time_error] = useState([]);
    const [status_error, set_status_error] = useState([]);
    const [cycle_error, set_cycle_error] = useState([]);


    var baseURL = process.env.REACT_APP_API_URL + 'public/api/v1/slots';
    const items = JSON.parse(localStorage.getItem('auth'));


    useEffect(() => {
        getSlots();
    }, []);

    useEffect(() => {
        const result = slots.filter(item => {
            // console.log(item.name);
            return item.name.toLowerCase().match(search.toLowerCase());
        })
        set_filterslots(result);
    }, [search, slots]);

    async function submitForm(e) {
        e.preventDefault();
        set_name_error([]);
        set_start_time_error([]);
        set_end_time_error([]);
        set_status_error([]);
        set_cycle_error([]);

        var result = await axios.post(baseURL, {
            name: name,
            start_time: start_time,
            end_time: end_time,
            cycle: cycle,
            status: status,
        }, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });

        // console.log(result);

        if (result.data.responseCode === 200) {
            toast.success("Success!! Slot has been Created.", { position: "bottom-right" });
            set_name("");
            set_start_time("");
            set_end_time("");
            set_status("");
            set_cycle("");
            getSlots();

        } else if (result.data.responseCode === 403) {
            set_name_error(result.data.error.name);
            set_start_time_error(result.data.error.start_time);
            set_end_time_error(result.data.error.end_time);
            set_status_error(result.data.error.status);
            set_cycle_error(result.data.error.cycle);
        }
        else {
            toast.error("Error!! Something Went Wrong.", { position: "bottom-right" });

        }
    }

    async function getSlots() {
        var slots = await axios.get(baseURL, {
            headers: {
                Authorization: 'Bearer ' + items.token
            }
        });
        console.log(slots);
        if (slots.data.responseCode === 200) {
            set_slots(slots.data.data);
            set_filterslots(slots.data.data);
        } else {
            set_slots([]);
        }
    }

    function timeFormat(value) {
        const timeString = "17:30:00";
        const time = new Date(`2000-01-01T${timeString}`);
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return time.toLocaleTimeString("en-US", options);
      }

    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: "4rem",
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },

        {
            name: 'Cycle',
            selector: row => row.cycle ? row.cycle.toUpperCase() : '',
            sortable: true,
            width: "35rem",

        },

        {
            name: 'Active Members',
            selector: row => row.count,
            sortable: true,
            width: "10rem"

        },

        {
            name: 'Start Time',
            selector: row => row.start_time,
            sortable: true

        },
        {
            name: 'End Time',
            selector: row => row.end_time,
            // sortable:true

        },

        {
            name: 'Status',
            selector: row => row.status === "1" ? 'Active' : 'Inactive',
            // sortable:true

        },

        {
            name: 'Action',
            selector: row =>
                <Link to={'/slotEdit/' + row.id}>
                    <button className='btn'><TbEdit /></button>

                </Link>
            // sortable:true

        }
    ];

    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const handleCheckboxChange = (e, weekday) => {
        if (e.target.checked) {
            set_cycle((prevSelected) => [...prevSelected, weekday]);
        } else {
            set_cycle((prevSelected) =>
                prevSelected.filter((day) => day !== weekday)
            );
        }
    };
    console.log(typeof(cycle), cycle);



    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Slot Create</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to=''>Dashboard</Link></li>
                            <li className="breadcrumb-item active">Slot Create</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card info-card sales-card">
                                <div className="card-body">
                                    <h5 className="card-title">Slot Create</h5>
                                    <form className="row g-3">
                                        <div className="col-12">
                                            <label htmlFor="inputNanme4" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="inputNanme4" value={name} onChange={(e) => { set_name(e.target.value) }} />
                                            <ul>
                                                {name_error ? (
                                                    name_error.map((item, key) => {
                                                        // console.log(item);
                                                        return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                    })
                                                ) : ""}

                                            </ul>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="inputEmail4" className="form-label">Cycle</label>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label htmlFor="mwf">MWF</label>
                                                    <input type="radio" name="cycle" onChange={(e) => { set_cycle([]);set_show_days(false); set_cycle(['monday', 'wednesday', 'friday']) }} />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="tts">TTS</label>
                                                    <input type="radio" name="cycle" onChange={(e) => { set_cycle([]);set_show_days(false); set_cycle(['tuesday', 'thursday', 'saturday']) }} />
                                                </div>
                                                <div className="col-md-2">

                                                    <label htmlFor="daily">Daily</label>
                                                    <input type="radio" name="cycle" onChange={(e) => { set_cycle([]);set_show_days(false); set_cycle(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']) }}/>
                                                </div>

                                                <div className="col-md-2">
                                                    <label htmlFor="daily">Custom</label>
                                                    <input type="radio" name="cycle" onChange={(e) => { set_cycle([]); set_show_days(true)}} />
                                                    {/* {console.log(show_days)} */}
                                                    {show_days ? <div>
                                                        {weekdays.map((weekday) => (
                                                            <label key={weekday} className="checkbox-container">
                                                                <input
                                                                    type="checkbox"
                                                                    value={weekday}
                                                                    checked={cycle.includes(weekday)}
                                                                    onChange={(e) => handleCheckboxChange(e, weekday)}
                                                                />
                                                                <span className="checkmark"></span>
                                                                {weekday}
                                                            </label>
                                                        ))}
                                                    </div> : ''}

                                                </div>



                                            </div>
                                            <ul>
                                                {cycle_error ? (
                                                    cycle_error.map((item, key) => {
                                                        // console.log(item);
                                                        return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                    })
                                                ) : ""}
                                            </ul>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="inputEmail4" className="form-label">Start Time</label>
                                            <input type="time" className="form-control" id="inputEmail4" value={start_time} onChange={(e) => { set_start_time(e.target.value) }} />
                                            <ul>
                                                {start_time_error ? (
                                                    start_time_error.map((item, key) => {
                                                        // console.log(item);
                                                        return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                    })
                                                ) : ""}
                                            </ul>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="inputEmail4" className="form-label">End Time</label>
                                            <input type="time" className="form-control" id="inputEmail4" value={end_time} onChange={(e) => { set_end_time(e.target.value) }} />
                                            <ul>
                                                {end_time_error ? (
                                                    end_time_error.map((item, key) => {
                                                        // console.log(item);
                                                        return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                    })
                                                ) : ""}
                                            </ul>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="phone" className="form-label">Status</label>
                                            <select className="form-control form-select" value={status} onChange={(e) => { set_status(e.target.value) }}>
                                                <option name="" >Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                            <ul>
                                                {status_error ? (
                                                    status_error.map((item, key) => {
                                                        // console.log(item);
                                                        return <li className="text-danger fw-bold" key={key}>{item}</li>
                                                    })
                                                ) : ""}
                                            </ul>
                                        </div>


                                        <div className="text-center">
                                            <button className="btn btn-primary" onClick={submitForm}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <CustomDatatable columns={columns} data={filterslots} title="Slot Logs"
                                searchdata={<input type='text' placeholder='Search Keyword'
                                    className='w-25 form-control' value={search}
                                    onChange={(e) => set_search(e.target.value)}
                                />} />

                        </div>
                    </div>
                </section>
            </main>
            <ToastContainer />
        </>
    )
}

export default SlotCreate;